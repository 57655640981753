<template>
    <div class="work-wrapper">
        <div class="timeline-dot"></div>
        <div class="work" :class="{ 'break-inside-avoid': isDescriptionShort }">
            <div class="break-inside-avoid">
                <h5>{{ data.position }}</h5>
                <p class="work__meta">
                    <span>{{ data.company }}</span>

                    <span>
                        <span>{{ transMonth(data.startMonth) }}&nbsp;</span>
                        <span>{{ data.startYear }}</span>
                        &ndash;
                        <span>{{ transMonth(data.endMonth) }}&nbsp;</span>
                        <span v-show="data.endMonth !== 'dnes'">{{ data.endYear }}</span>
                    </span>
                </p>
                <div class="work__technologies">
                    <k-chip v-for="tech in expertises" :key="tech.id" appearance="secondary" class="tw-uppercase">
                        {{ tech.name }}
                    </k-chip>

                    <k-chip
                        v-if="shouldHideTooManyExpertises"
                        @click="showAllExpertises = !showAllExpertises"
                        style="cursor: pointer"
                        appearance="secondary"
                    >
                        ...
                    </k-chip>
                </div>

                <div v-if="this.data.description" class="description">
                    <p>{{ this.data.description }}</p>
                </div>
            </div>

            <!-- <div v-if="descriptionLines" class="work__description">
                <p v-for="(line, idx) in descriptionLines" :key="idx">
                    <template v-if="line[0] === '•'">
                        <span style="padding-right: 0.75rem;">{{ line[0] }}</span>
                        <span>{{ line.slice(2) }}</span>
                    </template>
                    <template v-else>
                        <span style="padding-right: 0.75rem;">•</span>
                        <span>{{ line }}</span>
                    </template>
                </p>
            </div> -->
        </div>
    </div>
</template>

<script>
import KChip from "@web/components/core/k-chip";
export default {
    components: { KChip },
    props: ["data", "language", "isPrint"],
    data() {
        return {
            showAllExpertises: false
        };
    },
    computed: {
        shouldHideTooManyExpertises() {
            const length = this.data && this.data.expertises ? this.data.expertises.length : 0;

            return length > 6 && !this.isPrint;
        },
        expertises() {
            return this.shouldHideTooManyExpertises && !this.showAllExpertises ? this.data.expertises.slice(0, 6) : this.data.expertises;
        },
        isDescriptionShort() {
            if (!this.data.description) {
                return true;
            }
            const length = this.data.description.length;
            return (length || 0) < 256;
        },
        descriptionLines() {
            if (!this.data.description) {
                return null;
            }

            return this.data.description
                .split(/\r?\n/)
                .map(l => l.trim())
                .filter(Boolean);
        }
    },
    methods: {
        transMonth(month) {
            const enMonths = {
                dnes: "present",
                január: "january",
                február: "february",
                marec: "march",
                apríl: "april",
                máj: "may",
                jún: "june",
                júl: "july",
                august: "august",
                september: "september",
                október: "october",
                november: "november",
                december: "december"
            };

            if (this.language && this.language == "en") {
                return enMonths[month] || month;
            }
            return month;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.work-wrapper {
    position: relative;
    border: 0.0625rem solid transparent;
    border-left: 0.0625rem solid $koderia-whitesmoke;
    margin-left: 0.375rem;
}

.timeline-dot {
    position: absolute;
    top: 0;
    left: -0.375rem;
    width: 0.6875rem;
    height: 0.6875rem;
    background-color: $koderia-button-black;
    border-radius: 9999rem;
}

.work {
    padding-bottom: 1rem;
    padding-left: 2rem;
    margin-top: -0.4375rem;

    h5 {
        font-size: 1.25rem;
        line-height: 1.25;
        color: $koderia-gray-800;
        font-weight: 600;
    }
}

.work__meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 0.5rem;
    color: $koderia-gray-500;
}

.work__technologies {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;

    & > *:not(:last-child) {
        margin-right: 0.5rem;
    }

    & > * {
        margin-bottom: 0.5rem;
    }
}

.work__description {
    font-size: 0.875rem;
    line-height: 1.625rem;
    margin-top: 1rem;
}

.work__description-line {
    padding-left: 0.875rem;
}

.description {
    max-width: 660px;
    margin-top: 1rem;
    font-size: 0.875rem;
    line-height: 1.75rem;
    color: $koderia-gray-800;

    p {
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }
}
</style>
